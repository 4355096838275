<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>
        {{ Object.entries(this.data).length === 0 ? "ADD NEW" : "UPDATE" }} ITEM
      </h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
    </div>

    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar
      class="scroll-area--data-list-add-new"
      :settings="settings"
    >
      <div class="p-6">
        <!-- NAME -->
        <vs-input
          :label="$t('NameEn')"
          v-model="NameEN"
          class="mt-5 w-full"
          name="nameen"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('nameen')">{{
          errors.first("name_en")
        }}</span>

        <vs-input
          :label="$t('NameAr')"
          v-model="NameAR"
          class="mt-5 w-full"
          name="namear"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('namear')">{{
          errors.first("name_ar")
        }}</span>
        <vs-input
          :label="$t('Code Number')"
          v-model="CodeNumber"
          class="mt-5 w-full"
          name="namear"
        />
        <span class="text-danger text-sm" v-show="errors.has('CodeNumber')">{{
          errors.first("CodeNumber")
        }}</span>
              <vs-input
          :label="$t('Iso Code ')"
          required
          v-model="IsoCode"
          class="mt-5 w-full"
          name="IsoCode"
        />
        <span class="text-danger text-sm" v-show="errors.has('IsoCode')">{{
          errors.first("IsoCode")
        }}</span>
      </div>

      <vs-card title="icon" class="mt-5">
        <h4>{{ $t("Image") }}</h4>
        <template v-if="ImagePath">
          <div
            class="img-container w-64 mx-auto flex items-center justify-center"
          >
            <img :src="baseURL + ImagePath" alt="img" class="responsive" />
          </div>
        </template>
        <div class="modify-img flex justify-between mt-5">
          <input
            type="file"
            class="hidden"
            ref="updateImgInput"
            @change="updateCurrImg"
            accept="image/*"
          />
          <vs-button
            class="mr-4"
            type="flat"
            @click="$refs.updateImgInput.click()"
            >{{ $t("UpdateImage") }}</vs-button
          >
          <vs-button type="flat" color="#999" @click="ImagePath = null">{{
            $t("RemoveImage")
          }}</vs-button>
        </div>
      </vs-card>

      <div class="ml-6">
        <vs-checkbox class="mt-5" v-model="isTopDestination"
          >Is Top Destination
        </vs-checkbox>
      </div>
      <div class="ml-6">
        <vs-checkbox class="mt-8" v-model="IsAvailableToDoctors"
          >Is Available For Doctors
        </vs-checkbox>
      </div>
       <vs-input
       v-if="isTopDestination"
          :label="$t('OrderNumber')"
          v-model="OrderNumber"
          type="number"
          min="1"
          class="mt-5 w-full"
          name="OrderNumber"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('OrderNumber')">{{
          errors.first("OrderNumber")
        }}</span>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">{{
        $t("Save")
      }}</vs-button>
      <vs-button
        type="border"
        color="danger"
        @click="isSidebarActiveLocal = false"
        >{{ $t("Cancel") }}</vs-button
      >
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { domain } from "@/gloabelConstant.js";
export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },

  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        let {
          ID,
          NameEN,
          NameAR,
          CodeNumber,
          IsoCode,
          ImagePath,
          isTopDestination,
          IsAvailableToDoctors,
          OrderNumber,
        } = JSON.parse(JSON.stringify(this.data));
        this.ID = ID;
        this.NameEN = NameEN;
        this.NameAR = NameAR;
        this.OrderNumber = OrderNumber;
        this.CodeNumber = CodeNumber;
        this.IsoCode = IsoCode;
        this.ImagePath = ImagePath;
        this.IsAvailableToDoctors = IsAvailableToDoctors;
        this.isTopDestination = isTopDestination;
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.ID, this.Name, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    },
  },

  data() {
    return {
      baseURL: domain,
      uploadUrl: domain + "API/Common/UploadFile",

      ID: null,
      NameEN: "",
      NameAR: "",
      OrderNumber:null,
      CodeNumber: "",
      IsoCode: "",
      ImagePath: "",
      isTopDestination: null,
      IsAvailableToDoctors: null,
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    };
  },

  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      },
    },
    isFormValid() {
      return !this.errors.any() && this.NameEN;
    },
  },

  methods: {
    initValues() {
      if (this.data.ID) return;
      this.ID = null;
      this.NameAR = "";
      this.OrderNumber=null;
      this.NameEN = "";
      this.CodeNumber = "";
      this.IsoCode = "";
      this.ImagePath = "";
      this.isTopDestination = null;
      this.IsAvailableToDoctors = null;
    },
    updateCurrImg(input) {
      this.$store.dispatch("updateCurrImg", input).then((response) => {
        this.ImagePath = response.data;
      });
    },
    submitData() {
      var isTopDestinationCheck;
      var IsAvailableToDoctorsCheck;
      if (this.isTopDestination == true) {
        isTopDestinationCheck = true;
      } else {
        isTopDestinationCheck = false;
      }
      if (this.IsAvailableToDoctors == true) {
        IsAvailableToDoctorsCheck = true;
      } else {
        IsAvailableToDoctorsCheck = false;
      }
      this.$validator.validateAll().then((result) => {
        if (result) {
          const obj = {
            ID: this.ID,
            NameEN: this.NameEN,
            NameAR: this.NameAR,
            OrderNumber:this.OrderNumber,
            CodeNumber: this.CodeNumber,
            IsoCode: this.IsoCode,
            ImagePath: this.ImagePath,
            isTopDestination: isTopDestinationCheck,
            IsAvailableToDoctors: IsAvailableToDoctorsCheck,
          };
          if (this.ID !== null && this.ID >= 0) {
            this.$store
              .dispatch("CountryList/UpdateCountry", obj)
              .catch((err) => {
                console.error(err);
                window.showError(err.response.data.message);
              });
          } else {
            delete obj.ID;
            obj.popularity = 0;
            this.$store.dispatch("CountryList/AddCountry", obj).catch((err) => {
              console.error(err);
              window.showError(err.response.data.message);
            });
          }

          this.$emit("closeSidebar");
          this.initValues();
          //this.$store.dispatch("foodTypeList/fetchfoodTypeListItems");
        }
      });
    },
  },

  components: {
    VuePerfectScrollbar,
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
